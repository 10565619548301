<template>
	<v-layout>
		<v-container style="margin-left: 0px">
			<v-form ref="form">
				<v-container wrap>
					<v-row>
						<v-col cols="12" lg="8">
							<pui-field-set>
								<v-container class="pa-1 pt-4" wrap>
									<v-row>
										<v-col cols="12">
											<pui-text-field
												:label="$t('puiuser.usr')"
												toplabel
												v-model="model.usr"
												maxlength="100"
												disabled
											></pui-text-field>
										</v-col>
										<v-col cols="12">
											<pui-text-field
												:label="$t('puiuser.name')"
												v-model="model.name"
												maxlength="200"
												toplabel
												disabled
											></pui-text-field>
										</v-col>

										<v-col cols="12">
											<pui-text-field
												:label="$t('puiuser.email')"
												v-model="model.email"
												disabled
												toplabel
												maxlength="200"
											></pui-text-field>
										</v-col>

										<v-col cols="12" v-show="this.profNumber > 1">
											<pui-text-area
												:label="$t('puiuser.profiles')"
												v-model="this.selectedprofiles"
												disabled
												toplabel
												maxlength="200"
											></pui-text-area>
										</v-col>

										<v-col cols="12" v-show="this.profNumber == 1">
											<pui-text-field
												:label="$t('puiuser.profiles')"
												v-model="this.selectedprofiles"
												disabled
												toplabel
												maxlength="200"
											></pui-text-field>
										</v-col>

										<v-col cols="12">
											<pui-select
												v-model="model"
												modelName="puilanguage"
												itemValue="isocode"
												itemText="name"
												noeditable
												toplabel
												:label="$t('puiuser.language')"
												:itemsToSelect="[{ isocode: model.language }]"
												:modelFormMapping="{ isocode: 'language' }"
												:clearable="false"
												:fixedFilter="{
													groupOp: 'and',
													groups: [],
													rules: [{ field: 'enabled', op: 'eq', data: 1 }]
												}"
											></pui-select>
										</v-col>
										<v-col cols="12">
											<pui-select
												v-model="model"
												reactive
												toplabel
												:label="$t('userconfig.notification')"
												modelName="vlupyesnoquestions"
												:itemsToSelect="[{ valor: model.notifications }]"
												:modelFormMapping="{ valor: 'notifications' }"
												:itemValue="['valor']"
												:itemText="(item) => `${item.opcion}`"
												:key="keyNotifications"
											></pui-select>
										</v-col>
									</v-row>
								</v-container>
							</pui-field-set>
						</v-col>
					</v-row>
				</v-container>
			</v-form>
		</v-container>
		<pui-form-footer class="fluid">
			<v-container fluid class="pui-form-footer-btns ma-0">
				<v-row>
					<v-btn variant="outlined" color="primary" class="pui-form-footer-btns__desktop" @click="back()">{{ $t('form.cancel') }}</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="secondary" class="pui-form-footer-btns__desktop" @click="save(false)">{{ $t('form.save') }}</v-btn>
				</v-row>
			</v-container>
		</pui-form-footer>
		<v-snackbar color="error" v-model="showError" right>{{ serverError }}</v-snackbar>
	</v-layout>
</template>

<script>
export default {
	name: 'PuiUserSettingsForm',
	data() {
		return {
			showError: false,
			serverError: '',
			modelDependencyName: 'puiuser',
			model: {},
			originalModel: {},
			disabled: true,
			keyNotifications: 0,
			profilesList: [],
			selectedprofiles: {},
			profNumber: 0
		};
	},
	created() {
		this.model = this.$store.getters.getSession;
		this.getNotifications();
		this.originalModel = Object.assign({}, this.model);
		this.getProfiles();
	},
	watch: {
		resetPassword: 'onToggleResetPassword'
	},
	methods: {
		back() {
			this.$store.dispatch('puiRouterGoToDefaultEntryPage');
		},
		save() {
			if (!this.$refs.form.validate()) {
				return;
			}

			const patchObj = {};
			for (const prop in this.model) {
				if (!Object.prototype.hasOwnProperty.call(this.model, prop)) {
					continue;
				}
				if ((prop === 'name' || prop === 'email' || prop === 'language') && this.model[prop] !== this.originalModel[prop]) {
					patchObj[prop] = this.model[prop];
				}
			}

			//LLAMAR ENPOINT GESTAUT PARA CAMBIAR IDIOMA USUARIO
			this.changeGestautLang();
			this.changeNotifications();
			this.afterSave();
		},
		afterSave() {
			this.changeAppLang(this.model.language);
			this.$store.dispatch('puiRouterGoToDefaultEntryPage');
		},
		changeAppLang(lang) {
			window.localStorage.setItem('pui9_lang', lang);
			this.$store.commit('setUserLanguage', lang);
			this.$store.commit('setHttpRequestHeaders', { acceptLanguage: lang });
			this.$puiEvents.$emit('onPuiChangedLang', lang);
		},
		changeNotifications() {
			const pk = {
				usr: this.model.usr
			};
			let bool = false;
			switch (this.model.notifications) {
				case '0':
					bool = false;
					break;
				case '1':
					bool = true;
					break;
				default:
					break;
			}

			const patchData = {
				notify_email: bool
			};

			const url = '/nswuser/patch';
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('user.success');
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					url,
					patchData,
					() => {
						// Valid response, do code and close modal

						this.$puiNotify.success(message, title);

						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);

						resolve(true);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					},
					pk
				);
			});
		},
		changeGestautLang() {
			const patchData = {
				lang: this.model.language
			};
			const url = '/gestaut/changeLang';
			this.$puiRequests.getRequest(
				url,
				patchData,
				() => {},
				() => {}
			);
		},
		getNotifications() {
			this.$puiRequests.getRequest(
				'/nswuser/get',
				{
					usr: this.model.usr
				},
				(response) => {
					if (response.data) {
						if (response.data.notifyemail) {
							this.model.notifications = '1';
						} else {
							this.model.notifications = '0';
						}
						this.keyNotifications++;
					}
				},
				() => {}
			);
		},
		getProfiles() {
			const opts = {
				model: 'vlupprofiles',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'usr',
							op: 'eq',
							data: this.model.usr
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				this.setListProfiles(response.data);
			});
		},
		setListProfiles(list) {
			this.profilesList = [];
			let i = 0;
			// Filtrar manualmente para distinguir entre mayúsculas y minúsculas
			const filteredData = list.data.filter((profile) => profile.usr === this.model.usr);
			this.profilesList = filteredData.map((c) => ({
				id: i++,
				descripcion: c.descripcion,
				usr: c.codper
			}));

			let profilesText = '';
			for (let i = 0; i < this.profilesList.length; i++) {
				profilesText = profilesText + this.profilesList[i].descripcion + ' \n';
			}

			this.profNumber = i;
			this.selectedprofiles = profilesText;
		}
	},
	computed: {}
};
</script>
